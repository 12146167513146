<template>
  <el-dialog :title="`新增${title}`" :fullscreen="true" :modal-append-to-body="true"
             :append-to-body="true" :close-on-click-modal="false" :visible.sync="visible"
             :before-close="handleClose" v-if="visible">
    <el-steps :active="active" align-center class="m_b2">
      <el-step title="基本信息" description="请填写资源基本信息"></el-step>
      <el-step title="上传文件" description="请上传资源文件"></el-step>
      <el-step title="文件信息" description="请补全文件信息"></el-step>
      <el-step title="提交成功" description="资源信息提交成功"></el-step>
    </el-steps>
    <!--基本信息-->
    <el-form size="small" :model="inputForm" ref="inputForm" label-width="200px" v-show="active == 0">
      <el-row>
        <el-col :span="14">
          <el-form-item label="上传缩略图：" prop="thumbnail">
            <Upload v-if="visible" :uploadAskObj="uploadRequire" @getDataList="getDataList"></Upload>
          </el-form-item>
          <el-form-item label="资源名称：" prop="name"
                        :rules="[{required: true, whitespace: true, message: '资源名称不能为空', trigger: 'blur'}]">
            <el-input v-model.trim="inputForm.name" maxlength="100"
                      placeholder="请输入资源名称（100字内）"></el-input>
          </el-form-item>
          <el-form-item label="资源类型：" prop="type">
            {{ title }}
          </el-form-item>
          <el-form-item label="类型编码：" prop="type">{{ inputForm.resourceTypeCode }}</el-form-item>
          <el-form-item label="资源简称：" prop="resourceFoshot">
            <el-input v-model.trim="inputForm.resourceFoshot" maxlength="100"
                      placeholder="请输入资源简称（100字内）"></el-input>
          </el-form-item>
          <el-form-item label="资源描述：" prop="description">
            <el-input type="textarea" :rows="3" maxlength="100" v-model.trim="inputForm.description"
                      placeholder="请输入资源描述（100字内）"></el-input>
          </el-form-item>
          <el-row :gutter="20">
            <el-col :span="5">
              <el-form-item label="是否藏品：" prop="isCollection">
                <el-switch v-model="inputForm.isCollection"
                           :active-value="1"
                           :inactive-value="0"
                ></el-switch>
              </el-form-item>
            </el-col>
            <!--                        <el-col :span="10">-->
            <!--                            <el-form-item v-if="inputForm.isCollection"-->
            <!--                                          label="藏品编码：" prop="collectionNum">-->
            <!--                                <el-input size="small" v-model="inputForm.collectionNum"-->
            <!--                                          placeholder="请输入藏品编号"></el-input>-->
            <!--                            </el-form-item>-->
            <!--                        </el-col>-->
            <!--                        <el-col :span="5">-->
            <!--                            <el-button v-if="inputForm.isCollection"-->
            <!--                                       type="primary" @click="getCollection" size="small">-->
            <!--                                获取藏品-->
            <!--                            </el-button>-->
            <!--                        </el-col>-->
            <el-col :span="24" v-if="inputForm.isCollection" class="m_r1">
              <el-form-item label="关联藏品" prop="resourceDesc">
                <el-button type="primary" @click="relatedCollections">关联藏品</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22" :offset="4"
                    v-if="collectionShow && inputForm.isCollection == 1">
              <div class="b_d i_block p_all1 b-m">
                                <span class="p_r2"><span
                                    class="f_w">藏品名称：</span>{{ collectionMap.collectionName }}</span>
                <span class="p_r2"><span class="f_w">辅助账编号：</span>{{ collectionMap.helpNum }}</span>
                <span class="p_r2"><span class="f_w">总账编号：</span>{{ collectionMap.generalNum }}</span>
                <span class="p_r2"><span class="f_w">藏品分类：</span>{{ collectionMap.oneTypeName }}</span>
                <span class="p_r2"><span
                    class="f_w">是否鉴定：</span>{{ collectionMap.identified == 0 ? '是' : '否' }}</span>
                <span class="p_r2"><span
                    class="f_w">级别：</span>{{ getLeaveList(collectionMap.collectionLevel) }}</span>
                <span class="p_r2"><span
                    class="f_w">完残情况：</span>{{ collectionMap.integrityInfo }}</span>
                <span class="p_r2"><span class="f_w">完残程度：</span>{{ collectionMap.integrity }}</span>
              </div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="14">
          <el-col :span="12" v-for="(item, index) in dynamicForm" :key="item.id">
            <el-form-item v-if="item.onShow == 0" :label="`${item.fieldName}：`" prop="code">
              <el-date-picker
                  v-if="item.detailsShowType == 3"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  v-model="item.val"
                  type="date"
                  placeholder="选择日期" class="w100i">
              </el-date-picker>
              <el-input v-else-if="item.fieldEname == 'resourceTitle'"
                        v-model.trim="item.val" maxlength="500" placeholder="请输入（限500字）"></el-input>
              <el-input v-else
                        v-model.trim="item.val" maxlength="20" placeholder="请输入（限20字）"></el-input>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <!--上传文件-->
    <div v-show="active == 1">
      <el-upload :headers="myHeaders"
                 class="upload-demo"
                 drag
                 :accept="resourcesFileType.map(item=>{ return '.' + item }).join(',')"
                 :name="'files'"
                 :data="Incidental"
                 :action="uploadAddress"
                 multiple
                 :file-list="fileList"
                 :limit="fileMaxNum"
                 :on-remove="handleRemove"
                 :on-success="handleSuccess"
                 :on-error="uploadFailed"
                 :on-exceed="handleExceed"
                 :beforeUpload="beforeUpload">
        <div class="el-upload__text">上传文件</div>
      </el-upload>
    </div>
    <!--文件信息-->
    <div v-if="active == 2" class="epiphany">
      <el-row :gutter="10" class="m_b2" v-if="listType != 1">
        <el-col :span="7">
          <el-input size="small" v-model="fileName" maxlength="200" placeholder="请输入文件名称（限200字）"  clearable></el-input>
        </el-col>
        <el-col :span="8">
          <el-button type="primary" @click="select()" size="small" icon="el-icon-search">查询</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset()" size="small">重置</el-button>
        </el-col>
      </el-row>
      <el-table
          :data="dataList"
          v-loading="loading"
          size="small"
          :height="listType != 1 ? '400px': '440'"
          class="table">
        <el-table-column label="序号" type="index" width="80"></el-table-column>
        <el-table-column
            prop="url"
            show-overflow-tooltip
            label="文件预览" width="100">
          <template slot-scope="scope">
            <el-avatar :src="scope.row.url"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column
            prop="fileName"
            show-overflow-tooltip sortable
            label="文件名称" min-width="140" v-if="listType != 1">
        </el-table-column>
        <el-table-column
            prop="fileSizeFormat"
            show-overflow-tooltip sortable
            label="文件大小" min-width="140" v-if="listType != 1">
        </el-table-column>
        <el-table-column
            prop="fileDesc"
            label="文件描述" min-width="140" v-if="listType != 1">
          <template slot-scope="scope">
            <el-input v-model.trim="scope.row.fileDesc" size="small" maxlength="20"
                      placeholder="限20字"
                      clearable></el-input>
          </template>
        </el-table-column>
        <el-table-column
                prop="photographed"
                label="配音" min-width="140" v-if="listType == 2">
          <template slot-scope="scope">
            <el-input v-model.trim="scope.row.photographed" size="small" maxlength="100"
                      placeholder="限100字"
                      clearable></el-input>
          </template>
        </el-table-column>
        <el-table-column
                prop="impersonator"
                label="出镜" min-width="140" v-if="listType == 3">
          <template slot-scope="scope">
            <el-input v-model.trim="scope.row.impersonator" size="small" maxlength="100"
                      placeholder="限100字"
                      clearable></el-input>
          </template>
        </el-table-column>
        <el-table-column
                prop="copywriting"
                label="文案" min-width="140"  v-if="listType == 2 || listType == 3">
          <template slot-scope="scope">
            <el-input v-model.trim="scope.row.copywriting" size="small" maxlength="1000"
                      placeholder="限1000字"
                      clearable></el-input>
          </template>
        </el-table-column>
        <el-table-column
                prop="contribution"
                label="撰稿" min-width="140"  v-if="listType == 2 || listType == 3">
          <template slot-scope="scope">
            <el-input v-model.trim="scope.row.contribution" size="small" maxlength="100"
                      placeholder="限100字"
                      clearable></el-input>
          </template>
        </el-table-column>
        <el-table-column
            prop="shotUser"
            label="拍摄人" min-width="140" v-if="listType == 3">
          <template slot-scope="scope">
            <el-input v-model.trim="scope.row.shotUser" size="small" maxlength="20"
                      placeholder="限20字"
                      clearable></el-input>
          </template>
        </el-table-column>
        <el-table-column
            prop="shotTask"
            label="拍摄任务" min-width="140" v-if="listType == 3">
          <template slot-scope="scope">
            <el-input v-model.trim="scope.row.shotTask" size="small" maxlength="80"
                      placeholder="限80字"
                      clearable></el-input>
          </template>
        </el-table-column>
        <el-table-column
            prop="shotAddress"
            label="拍摄地点" min-width="140" v-if="listType == 3">
          <template slot-scope="scope">
            <el-input v-model.trim="scope.row.shotAddress" size="small" maxlength="20"
                      placeholder="限20字"
                      clearable></el-input>
          </template>
        </el-table-column>
        <el-table-column
                prop="fileName"
                show-overflow-tooltip sortable
                label="事由/题名" min-width="140" v-if="listType == 1">
        </el-table-column>
        <el-table-column prop="photoNumber" label="照片号/底片号" width="140" v-if="listType == 1">
          <template slot-scope="scope">
            <el-input v-model.trim="scope.row.photoNumber" size="small" maxlength="20"
                      placeholder="限20字"
                      clearable></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="time" label="时间" width="160" v-if="listType == 1">
          <template slot-scope="scope">
            <el-date-picker type="date" value-format="yyyy-MM-dd"
                            format="yyyy-MM-dd" v-model="scope.row.time" size="small" placeholder="请选择"
                            class="w100i"></el-date-picker>
          </template>
        </el-table-column>
        <el-table-column prop="resourceYear" label="年度" width="160" v-if="listType == 1">
          <template slot-scope="scope">
            <el-date-picker
                value-format="yyyy"
                format="yyyy"
                v-model="scope.row.resourceYear"
                type="year" size="small"
                placeholder="请选择" class="w100i">
            </el-date-picker>
          </template>
        </el-table-column>
        <el-table-column prop="place" label="地点" width="140" v-if="listType == 1">
          <template slot-scope="scope">
            <el-input v-model.trim="scope.row.place" size="small" maxlength="20"
                      placeholder="限20字"
                      clearable></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="resourceCharacter" label="人物" width="140" v-if="listType == 1">
          <template slot-scope="scope">
            <el-input v-model.trim="scope.row.resourceCharacter" size="small" maxlength="20"
                      placeholder="限20字" clearable></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="background" label="背景" width="140" v-if="listType == 1">
          <template slot-scope="scope">
            <el-input v-model.trim="scope.row.background" size="small" maxlength="50"
                      placeholder="限50字" clearable></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="photographer" label="拍摄者" width="140" v-if="listType == 1">
          <template slot-scope="scope">
            <el-input v-model.trim="scope.row.photographer" size="small" maxlength="20"
                      placeholder="限20字" clearable></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="author" label="作者" width="140" v-if="listType == 1">
          <template slot-scope="scope">
            <el-input v-model.trim="scope.row.author" size="small" maxlength="20"
                      placeholder="限20字" clearable></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="albumName" label="相册名" width="140" v-if="listType == 1">
          <template slot-scope="scope">
            <el-input v-model.trim="scope.row.albumName" size="small" maxlength="20"
                      placeholder="限20字" clearable></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="keyword" label="主题词/关键词" width="140" v-if="listType == 1">
          <template slot-scope="scope">
            <el-input v-model.trim="scope.row.keyword" size="small" maxlength="20"
                      placeholder="限20字" clearable></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="classificationLevel" label="密级" width="140" v-if="listType == 1">
          <template slot-scope="scope">
            <el-input v-model.trim="scope.row.classificationLevel" size="small" maxlength="10"
                      placeholder="限10字" clearable></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="storagePeriod" label="保管期限" width="150" v-if="listType == 1">
          <template slot-scope="scope">
            <el-input v-model.trim="scope.row.storagePeriod" size="small" maxlength="3"
                      placeholder="如永久或10年" clearable></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="typeSpecifications" label="类型规格" width="140" v-if="listType == 1">
          <template slot-scope="scope">
            <el-input v-model.trim="scope.row.typeSpecifications" size="small" maxlength="10"
                      placeholder="限10字" clearable></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="remarks" label="备注" width="140" v-if="listType == 1">
          <template slot-scope="scope">
            <el-input v-model.trim="scope.row.remarks" size="small" maxlength="200"
                      placeholder="限200字" clearable></el-input>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="active == 3">
      <el-result icon="success" title="资源提交成功" :sub-title="successInfo"></el-result>
    </div>
    <div class="text_center btnBox">
      <el-button type="primary" @click="prev" size="small" v-if="active > 0 && active < 3">上一步</el-button>
      <el-button type="primary" @click="next" size="small" v-if="active < 2" :disabled="active == 1 && !fileList.length">下一步</el-button>
      <el-button type="primary" @click="next" size="small" v-if="active == 2" v-no-more-click>提交</el-button>
      <el-button type="primary" @click="confirm" size="small" v-if="active == 3">确定</el-button>
    </div>
    <coll-list ref="collectionList"
               @seltDataColl="(data)=>{this.collectionMap = data;this.collectionShow = true;this.collectionId = data.id}"></coll-list>
  </el-dialog>
</template>

<script>
import Upload from "@/components/Upload/Upload"
import CollList from "@/views/modules/dyyg/gather/resource/collList.vue";
import {getUploadNotCurrentlySupported} from "@/utils";

export default {
  props: {
    resourceType: Number,
    // default:1,
  },
  components: {CollList, Upload},
  data() {
    return {
      visible: false,
      listType: '',
      title: '',
      active: 0,
      // 缩略图上传要求
      uploadRequire: {
        fileType: ['jpg', 'png', 'jpeg'],
        showFile: true, // 是否显示已上传文件列表
        count: 1, // 上传文件数量 0表示不限制文件的数量
        megaNum: 10,
        showTips: true,
      },
      inputForm: {
        thumbnail: '',
        name: '',
        resourceFoshot: '',
        writtenDate: '',
        description: '',
        isCollection: 0,
        collectionNum: '', //藏品编号
        resourceTypeCode: "",
      },
      collectionShow: false,
      dynamicForm: [],
      // 上传文件
      uploadAddress: '',
      myHeaders: {Authorization: sessionStorage.getItem('token'), satoken: sessionStorage.getItem('token')},
      fileList: [],
      fileMaxNum: 100,
      // 文件信息
      fileName: '',
      Incidental: {},//附带参数
      resourceParameters: {},
      dataList: [],
      allDataList: [], // 全部数据
      pageNo: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      successInfo: '',
      filePath: '',
      resourcesFileType: [], //上传文件资源格式限制
      notResourcesFileType: [],//上传文件暂不支持的格式

      allTableByResource: [],//所有元数据信息
      collectionName: '',//藏品名称
      collectionYears: '',//藏品年代
      collectionType: '',//藏品类型
      collectionTexture: '',//藏品质地
      collectionLevel: '',
      collectionId: '',//藏品id
      onSaveResource: false, // 是否已经生成资源
      onEditFiles: false, // 是否已经填写了文件信息数据
      onWatch: false, // 文件是否开启监听
      collectionMap: {}, //关联的藏品数据
      leaveList: [],

      topicLibraryId: '',//宣传专题库 新增时的专题库id
    }
  },
  computed: {
    newList() {
      return JSON.parse(JSON.stringify(this.dataList))
    }
  },
  watch: {
    // CL 监听文件输入框是否发生改变
    newList(newList) {
      if (this.onWatch && newList.length == this.allDataList.length) {
        if (this.allDataList.length > 0) {
          this.allDataList = newList.map(item2 => {
            newList.map(item => {
              if (item2.id === item.id) {
                item2 = item
              }
            })
            return item2
          })
        }
      }
    },
  },

  mounted() {
    this.$axios(this.api.collection.listLevelSelect).then(data => {
      if (data.status) {
        this.leaveList = data.data
      }
    })
  },
  methods: {
    // 获取上传图片数量
    getFileMaxNum() {
      this.$axios(this.api.digital.downloadSetting, {type: 1}).then(data => {
        if (data.status) {
          this.fileMaxNum = parseInt(data.data.records[0].text)
        }
      })
    },
    //获取藏品
    getCollection() {
      if (this.inputForm.collectionNum.replace(/(^\s*)|(\s*$)/g, '') == '') {
        this.$message.error('请输入藏品编号')
        return;
      } else {
        this.$axios(this.api.rcPage.getByNum, {
          num: this.inputForm.collectionNum.replace(/(^\s*)|(\s*$)/g, ''),
        }, 'get').then(res => {
          if (res.status) {
            if (res.data == null) {
              res.data = {}
            }
            this.collectionLevel = res.data.collection_level;
            this.collectionName = res.data.collection_name;
            this.collectionYears = res.data.collection_years;
            this.collectionType = res.data.collection_type;
            this.collectionTexture = res.data.collection_texture;
            this.collectionId = res.data.id;
          } else {
            this.$message.error('未查询到该藏品编号')
          }
        })
      }
      this.collectionShow = true
    },

    init(id, listType, topicLibraryId) {
      if (listType == 1) {
        this.getFileMaxNum()
      }
      this.topicLibraryId = topicLibraryId
      this.collectionMap = {}
      this.listType = listType
      this.title = this.$dictUtils.getDictLabel("resource_type", listType, '')
      this.resourcesFileType = this.getResourceUploadFileFormat(listType)
      this.notResourcesFileType = this.getUploadNotCurrentlySupported(listType)
      this.visible = true
      this.$nextTick(() => {
        this.active = 0;
        this.inputForm.collectionNum = '';
        this.inputForm.resourceTypeCode = "A00" + this.resourceType
        this.collectionShow = false;
      })

      this.getTableByResourceName();
      this.uploadAddress = this.$globalUrl() + this.api.rcPage.renderCommonResources;
      this.uploadRequire.list = []
      this.uploadRequire.showFile = true
    },

    // 获取上传缩略图数据
    getDataList(data) {
      this.filePath = data.response.data.path
      if (data.response.status) {
        this.$message.success('上传成功')
      }
    },

    //关联藏品
    relatedCollections() {
      this.$refs.collectionList.int()
    },

    getLeaveList(leave) {
      let data = this.leaveList.filter(item => {
        return item.level == leave
      })
      if (data.length) {
        return data[0].levelName
      }
    },

    //获取元数据信息
    getTableByResourceName() {
      this.dynamicForm = [];
      this.$axios(this.api.rcPage.getTableByResourceName, {
        resourceName: '新增资源',
      }, 'get').then(res => {
        if (res.status) {
          this.allTableByResource = res.data.data;
          this.allTableByResource.map(item => {
            if (item.fieldName != '藏品id') {
              this.dynamicForm.push(item);
            }
          })
        }
      })
    },

    //给文件上传附带参数赋值
    assignment() {
      this.allTableByResource.map(item => {
        this.dynamicForm.map(item2 => {
          if (item.fieldName == '是否藏品') {
            this.resourceParameters[item.fieldEname] = this.inputForm.isCollection;
          } else if (item.fieldName == '藏品编码') {
            this.resourceParameters[item.fieldEname] = this.inputForm.collectionNum;
          } else if (item.fieldName == item2.fieldName) {
            this.resourceParameters[item.fieldEname] = item2.val;
          }
        })
      })
      if (this.collectionShow && this.inputForm.isCollection) {
        this.resourceParameters.collectionId = this.collectionId;
      } else {
        this.resourceParameters.collectionId = '';
      }
      this.resourceParameters.resourceType = this.resourceType;
      this.resourceParameters.onCollection = this.inputForm.isCollection;
      this.resourceParameters.resourceName = this.inputForm.name;
      this.resourceParameters.resourceDesc = this.inputForm.description;
    },

    // 上一步
    prev() {
      this.active--
      this.allDataList = this.dataList
    },
    // 下一步
    next() {
      if (this.active == 0) {
        this.$refs['inputForm'].validate((valid) => {
          if (valid) {
            if (this.inputForm.isCollection == 1 && !this.collectionMap.id) {
              this.$message.warning("未查找到对应藏品请重新输入！")
              return
            }
            this.assignment();
            this.newResources();
          }
        })
      } else if (this.active == 1 && this.fileList.length == 0) {
        this.$message('请上传文件');
      } else if (this.active == 1 && this.fileList.length) {
        this.refreshList(1);
        this.active++
      } else if (this.active == 2) {
        this.onEditFiles = false
        this.refreshList(2);
      } else {
        this.active++
      }
    },
    // 更新数组
    updateDataList() {
      this.allDataList = this.dataList
    },
    //新增资源
    newResources() {
      this.$set(this.resourceParameters, 'thumbPath', this.filePath)
      this.$set(this.resourceParameters, 'resourcesTitleType', this.title)
      this.$set(this.resourceParameters, 'resourceFoshot', this.inputForm.resourceFoshot)
      this.$set(this.resourceParameters, 'writtenDate', this.inputForm.writtenDate)
      this.$set(this.resourceParameters, 'resourceTypeCode', this.inputForm.resourceTypeCode)
      if (this.resourceParameters.onCollection == 0) {
        this.$set(this.resourceParameters, 'collectionName', this.collectionMap.collectionName)
        this.$set(this.resourceParameters, 'collectionId', this.collectionMap.id)
      } else {
        this.$set(this.resourceParameters, 'collectionName', this.collectionMap.collectionName)
      }
      this.$axios(this.api.rcPage.temporaryCommonResources, this.resourceParameters, 'post').then(res => {
        if (res.status) {
          this.Incidental.resourcesId = res.data;
          this.Incidental.resourceType = this.resourceParameters.resourceType;
          this.$set(this.resourceParameters, 'id', res.data)
          this.active++
          this.onSaveResource = true
        } else {
          this.$message.error(res.msg)
        }
        this.filePath = null
      })
    },

    // 移除上传的文件
    handleRemove(file, fileList) {
      this.$axios(this.api.rcPage.removeCacheFiles + '?&resourceFileId=' + file.id + '&resourcesId=' + this.Incidental.resourcesId, {}, 'post').then(res => {
        if (res.status) {
          this.fileList = fileList;
          this.onEditFiles = false
        }
      })
    },
    // 限制文件大小跟格式
    beforeUpload(file) {
      if (file.name.indexOf('\'') > -1) {
        this.$message.error(`文件${file.name}包含非法字符，请进行更名操作`)
        return false;
      }
      let reapetFile = this.fileList.filter(item => {
        return item.name == file.name
      })
      if (reapetFile.length) {
        this.$message.error(`文件${reapetFile[0].name}重复上传，请进行更名操作`)
        return false
      }

      let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      testmsg = testmsg.toLowerCase();
      if (this.notResourcesFileType && this.notResourcesFileType.length != 0) {
        let testType = Boolean(this.notResourcesFileType.find(item => item == testmsg) == testmsg)
        if (testType) {
          this.$message.error(`暂时不支持上传该格式文件!`);
          return false
        }
      }
      if (this.resourcesFileType.length != 0) {
        let testType = Boolean(this.resourcesFileType.find(item => item == testmsg) == testmsg)
        if (!testType) {
          this.$message.error(`上传文件只能是${this.resourcesFileType.join('、')}格式!`);
          return false
        }
      }
      let megaNum = 1024
      // if (this.listType == 1) {
      //   megaNum = 10
      // }
      const isLt2M = file.size / 1024 / 1024 <= megaNum
      if (!isLt2M && megaNum > 0) {
        this.$message.error("上传文件大小不能超过" + megaNum + "MB!");
        return false
      }
    },
    // 文件上传成功时的钩子
    handleSuccess(response, file, fileList) {
      this.fileList = fileList;
      this.onEditFiles = false
      if (response.status) {
        file.id = response.data;
        //this.fileList = fileList;
      } else {
        this.$message.error(response.msg + "：" + file.name)
      }
      if (this.fileList != null && this.fileList.length > 0) {
        this.fileList = this.fileList.filter(file => file.response.status)
      }
    },

    handleExceed() {
      this.$message.error("只能上传" + this.fileMaxNum + "个文件");
    },

    //文件上传失败的钩子
    uploadFailed(err, file, fileList) {
      this.$message.error('文件上传失败')
    },
    select() {
      this.onEditFiles = false
      this.onWatch = false
      this.refreshList()
    },
    // 文件信息数据查询
    refreshList(type) {
      if (type == 2) {
        this.fileName = ''
      }
      // 返回上一步不清空数据
      if (this.onEditFiles) return
      this.dataList = [];
      this.loading = true;
      this.$axios(this.api.rcPage.uploadDataPreview, {
        resourcesId: this.Incidental.resourcesId,
        fileName: this.fileName,
        current: this.pageNo,
        size: 10000,
      }, 'get').then(res => {
        if (res.status) {
          this.loading = false;
          this.total = parseInt(res.data.total);
          res.data.records.map(item => {
            item.fileDesc = '';
            item.photographed = '';
            item.impersonator = '';
            item.copywriting = '';
            item.contribution = '';
            item.shotUser = '';
            item.shotTask = '';
            item.shotAddress = '';
            item.photoNumber = '';
            item.reason = '';
            item.time = '';
            item.resourceYear = '';
            item.place = '';
            item.resourceCharacter = '';
            item.background = '';
            item.photographer = '';
            item.author = '';
            item.albumName = '';
            item.keyword = '';
            item.classificationLevel = '';
            item.storagePeriod = '';
            item.typeSpecifications = '';
            item.operationField = '';
            item.remarks = '';
            this.dataList.push(item);
          })
          // CL 此操作对列表进行查询进行数据储存
          if (this.allDataList.length > 0) {
            this.dataList = this.dataList.map(item2 => {
              this.allDataList.map(item => {
                if (item2.id === item.id) {
                  item2 = item
                }
              })
              return item2
            })
          }
          this.onWatch = true;
          if (type == 1) {
            this.$nextTick(() => {
              this.updateDataList();
            })
          }
          if (type == 2) {
            this.$nextTick(() => {
              this.executeWarehousing()
            })
          }
        }
      })
    },

    //文件入库
    executeWarehousing() {
      let param = {
        resourceType: this.resourceParameters.resourceType,
        resourcesId: this.Incidental.resourcesId,
        body: this.dataList,
      }
      if (this.topicLibraryId) {
        this.$set(param, 'thematicId', this.topicLibraryId)
      }
      this.$axios(this.api.rcPage.addCommonResources, param, 'post').then(res => {
        if (res.status) {
          this.active++
          this.successInfo = "成功新增资源：" + this.inputForm.name + "(资源名称)，文件数量" + this.total + "个。"
        } else {
          this.$message.error("提交失败")
        }
      })
    },

    // 删除
    del(id) {
      this.$confirm(`确定删除所选项吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios(this.api.sys.list, {'ids': id}, 'post').then(data => {
          if (data && data.status) {
            this.$message.success(data.data)
            this.refreshList()
          }
        })
      })
    },
    // 确定
    confirm() {
      this.handleClose();
      this.$emit('refreshDataList')
    },
    // 重置
    reset() {
      this.fileName = '';
      this.pageNo = 1;
      this.onEditFiles = false
      this.onWatch = false
      this.refreshList()
    },

    //右上角关闭弹窗
    handleClose() {
      this.$refs['inputForm'].resetFields();
      this.fileList = [];
      this.$axios(this.api.rcPage.previousRemoveFolder + "?&resourceType=" +
          this.resourceParameters.resourceType + '&resourcesId=' +
          this.Incidental.resourcesId, {}, 'post').then(res => {
      })
      this.$set(this.resourceParameters, 'id')
      this.collectionName = ''
      this.uploadRequire.list = []
      this.uploadRequire.showFile = false
      this.visible = false;
      this.onWatch = false;
      this.$emit('refreshDataList')
    }
  }
}
</script>

<style scoped>
.el-upload-dragger .el-upload__text {
  line-height: 180px;
  font-size: 12px;
  color: #aaa;
}

.upload-demo >>> .el-upload {
  display: block;
}

.upload-demo >>> .el-upload-dragger {
  margin: 0 auto;
}

.epiphany {
  padding: 0 100px;
}

.btnBox {
  padding-top: 30px;
}

.b-m {
  margin-bottom: 20px;
}

.upload-demo::v-deep .el-upload-list {
  height: calc(100vh - 460px) !important;
  overflow-y: auto;
}
</style>
