<template>
  <el-dialog class="bulkImport" title="上传表格" :modal-append-to-body="true"
             :append-to-body="true" :visible.sync="visible" width="70%"
             :close-on-click-modal="false"
             :before-close="handleClose">
    <el-steps class="m_b2" :active="bringVolActive" align-center>
      <el-step title="上传文件" description="根据模板修改后上传"></el-step>
      <el-step title="预览数据" description="预览并检查数据"></el-step>
      <el-step title="执行导入" description="数据导入至服务器"></el-step>
      <el-step title="导入成功" description="完成数据批量导入"></el-step>
    </el-steps>
    <!--上传文件-->
    <div v-if="bringVolActive == 0">
      <div v-if="!showAbnormal">
        <div class="flex_l_c b_d m_t1">
          <img src="../../../../../../assets/img/xlsIcon.png" class="xlsIcon b_r">
          <div class="uploadInfo">
            <div class="f_w">填写导入资源的信息</div>
            <div class="tips">
              请按照数据模板的格式准备导入数据，模板中的表头名称不可更改，表头行不能删除，展示列数据不会计入、单次导入的数据不超过1000条。
            </div>
            <el-button @click="downloadTemplate" type="text">下载模板</el-button>
          </div>
        </div>
        <div class="flex_l_c b_d m_t1">
          <img src="../../../../../../assets/img/xlsIcon.png" class="xlsIcon b_r">
          <div class="uploadInfo">
            <div class="f_w">上传填好的资源信息表</div>
            <div class="tips">文件后缀名必须为xls或xlsx (即Excel格式) ，文件大小不得大于10M</div>
            <Upload class="m_r1" :uploadAskObj="uploadRequire" @getDataList="fileDataGetDataList"></Upload>
          </div>
        </div>
      </div>
      <!--上传成功后展示-->
      <div v-else>
        <div class="flex_l_c b_d m_t1 m_b2">
          <img src="../../../../../../assets/img/abnormalIcon.png" class="zipIcon b_r">
          <div class="uploadInfo">
            <div class="m_b1"><span class="f_w">正常数量条数: </span><span
                class="greenColor">{{ successTotal }}条</span></div>
            <div><span class="f_w">异常数量条数: </span><span class="redColor">{{ errorTotal }}条</span>
            </div>
          </div>
        </div>
        <div class="b_d p_all1 abnormalTips">
          <div class="f_w m_b1">异常提示：</div>
          <div v-for="item in errorList" :key="item.errorInfo" class="m_b1 tips">第{{ item.row }}行： {{
              item.errorInfo
            }}
          </div>
        </div>
      </div>
    </div>
    <!--数据预览-->
    <div v-if="bringVolActive == 1">
      <el-table
          v-if="resourceType == 0 "
          :data="fileList"
          size="small"
          height="calc(100vh - 442px)"
          class="table">
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column prop="resourceName" label="资源名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="resourceTypesStr" label="资源类型" show-overflow-tooltip></el-table-column>
        <el-table-column prop="resourceTypeCode" label="类型编码" show-overflow-tooltip></el-table-column>
        <el-table-column prop="resourceFoshot" label="资源简称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="resourceTitle" label="主题标题" show-overflow-tooltip></el-table-column>
        <el-table-column prop="resourceSource" label="资源来源" show-overflow-tooltip></el-table-column>
        <el-table-column prop="writtenTime" label="成文时间" show-overflow-tooltip></el-table-column>
        <el-table-column prop="collectionIdStr" label="藏品辅助账编号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="collectionName" label="藏品名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="resourceDesc" label="资源描述" show-overflow-tooltip></el-table-column>
        <el-table-column prop="oldId" label="文件编号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="fileName" label="文件名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="modelTypeStr" label="数据分级" show-overflow-tooltip></el-table-column>
        <el-table-column prop="fileDesc" label="文件描述" show-overflow-tooltip></el-table-column>
        <el-table-column prop="originalDir" label="原文目录" show-overflow-tooltip></el-table-column>
        <el-table-column prop="originalName" label="原文名称" show-overflow-tooltip></el-table-column>
      </el-table>
      <el-table
          v-else-if="resourceType == 0 "
          :data="fileList"
          size="small"
          height="calc(100vh - 442px)"
          class="table">
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column prop="resourceName" label="资源名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="resourceTypesStr" label="资源类型" show-overflow-tooltip></el-table-column>
        <el-table-column prop="resourceTypeCode" label="类型编码" show-overflow-tooltip></el-table-column>
        <el-table-column prop="resourceFoshot" label="资源简称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="resourceTitle" label="主题标题" show-overflow-tooltip></el-table-column>
        <el-table-column prop="resourceSource" label="资源来源" show-overflow-tooltip></el-table-column>
        <el-table-column prop="writtenTime" label="成文时间" show-overflow-tooltip></el-table-column>
        <el-table-column prop="resourceDesc" label="资源描述" show-overflow-tooltip></el-table-column>
        <el-table-column prop="imgType" label="图片类型" show-overflow-tooltip></el-table-column>
        <el-table-column prop="oldId" label="原始编号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="masterId" label="拍摄主体编号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="photoNumber" label="照片号/底片号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="reason" label="题名" show-overflow-tooltip></el-table-column>
        <el-table-column prop="time" label="时间" show-overflow-tooltip></el-table-column>
        <el-table-column prop="resourceYear" label="年度" show-overflow-tooltip></el-table-column>
        <el-table-column prop="place" label="地点" show-overflow-tooltip></el-table-column>
        <el-table-column prop="resourceCharacter" label="人物" show-overflow-tooltip></el-table-column>
        <el-table-column prop="background" label="描述" show-overflow-tooltip></el-table-column>
        <el-table-column prop="photographer" label="拍摄者" show-overflow-tooltip></el-table-column>
        <el-table-column prop="author" label="作者" show-overflow-tooltip></el-table-column>
        <el-table-column prop="keyword" label="主题词/关键词" show-overflow-tooltip></el-table-column>
        <el-table-column prop="storagePeriod" label="保管期限" show-overflow-tooltip></el-table-column>
        <el-table-column prop="typeSpecifications" label="类型规格" show-overflow-tooltip></el-table-column>
        <el-table-column prop="remarks" label="备注" show-overflow-tooltip></el-table-column>
        <el-table-column prop="originalDir" label="原文目录" show-overflow-tooltip></el-table-column>
        <el-table-column prop="originalName" label="原文名称" show-overflow-tooltip></el-table-column>
      </el-table>
      <el-table
          v-else
          :data="fileList"
          size="small"
          height="calc(100vh - 442px)"
          class="table">
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column prop="resourceName" label="资源名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="resourceTypesStr" label="资源类型" show-overflow-tooltip></el-table-column>
        <el-table-column prop="resourceTypeCode" label="类型编码" show-overflow-tooltip></el-table-column>
        <el-table-column prop="resourceFoshot" label="资源简称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="resourceTitle" label="主题标题" show-overflow-tooltip></el-table-column>
        <el-table-column prop="resourceSource" label="资源来源" show-overflow-tooltip></el-table-column>
        <el-table-column prop="writtenTime" label="成文时间" show-overflow-tooltip></el-table-column>
        <el-table-column prop="collectionIdStr" label="藏品辅助账编号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="collectionName" label="藏品名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="resourceDesc" label="资源描述" show-overflow-tooltip></el-table-column>

<!--        <el-table-column prop="imgType" label="文件序号" show-overflow-tooltip></el-table-column>-->

<!--        <el-table-column prop="fileName" label="文件名称" show-overflow-tooltip></el-table-column>-->
<!--        <el-table-column prop="fileDesc" label="文件描述" show-overflow-tooltip></el-table-column>-->
        <el-table-column prop="originalDir" label="原文目录" show-overflow-tooltip></el-table-column>
        <el-table-column prop="originalName" label="原文名称" show-overflow-tooltip></el-table-column>
      </el-table>

      <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageNo"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="total"
          background
          layout="total, prev, pager, next, sizes">
      </el-pagination>
    </div>
    <!--导入数据-->
    <div v-if="bringVolActive == 2" class="importSuccess">
      <el-progress :percentage="importProgress" :stroke-width="20" :status="zhaungStatus"></el-progress>
      <div class="text_center importTips m_t2">{{ zhagntaiWenzi }}</div>
    </div>
    <!--导入完成-->
    <div v-if="bringVolActive == 3" class="importData text_center">
      <div class="el-icon-circle-check greenColor"></div>
      <div class="f_w m_b1 title">数据导入完成</div>
      <div class="tips">您已成功导入{{ total }}条数据</div>
      <div style="margin-bottom: 20px">
        <el-button size="small" type="primary" @click="downresult(1)">下载导入结果</el-button>
      </div>
      <el-button size="small" type="primary" @click="handleClose(1)">返回</el-button>
    </div>
    <span slot="footer" class="dialog-footer">
            <el-button size="small" type="primary" plain v-if="bringVolActive != 3 && showAbnormal"
                       @click="reUpload">重新上传</el-button>
            <el-button size="small" type="primary" @click="next()" v-if="bringVolActive != 3">下一步</el-button>
        </span>
  </el-dialog>
</template>

<script>
import Upload from "@/components/Upload/Upload"; // 导入上传组件
export default {
  components: {Upload},


  //目前下面这三个数据都是必传，这里只是模拟
  props: {
    resourceType: { //资源类型
      required: true,
      // default:0,
    },
    resourceName: { //资源名称
      required: true,
      // default:'三维资源',
    },
    originalId: { //元数据id
      required: true,
      // default:'946049961995272192',
    },
    resourceState: { //0采集1编目
      default: '0',
    },
  },
  data() {
    return {
      visible: false,
      bringVolActive: 0,
      fileStatus: false,
      uploadRequire: {
        list: [],
        btnName: '上传文件', // 按钮名称
        showFile: true, // 是否显示已上传文件列表
        megaNum: 10, // 上传文件兆数 0表示不限兆数
        count: 1, // 上传文件数量 0表示不限制文件的数量
        fileType: ['xls', 'xlsx'],
        uploadPath: "cultural",
        UploadAddress: 'zyd-zgww/resourceUpload/resourceUpload',//上传地址
        attachedParameters: {
          resourceType: '', //资源类型
          resourceState: '0',
        }
      },
      showAbnormal: false, // true 展示上传后的异常提示
      successTotal: '',//正常数量条数
      errorTotal: '',//异常数量条数
      errorList: [],//异常数据列表
      listKey: '',
      recordKey: '',
      importProgress: 1, //导入进度
      zhaungStatus: '',//导入状态
      zhagntaiWenzi: '正在导入数据',
      uploadStatus: false,//上传文件状态
      // 数据预览
      fileList: [],
      listHead: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,

      topicLibraryId: '',
    }
  },
  mounted() {
    //this.uploadRequire.attachedParameters.resourceType = this.resourceType;
  },
  methods: {
    init(topicLibraryId) {
      this.visible = true;
      this.topicLibraryId = topicLibraryId
      this.uploadStatus = false;
      this.uploadRequire.attachedParameters.resourceType = this.resourceType;
      this.uploadRequire.attachedParameters.resourceState = this.resourceState;
    },
    // 上一步
    prev() {
      if (this.bringVolActive > 0) {
        this.bringVolActive--
      }
    },
    // 下一步
    next() {
      if (this.bringVolActive == 0) {
        if (!this.uploadStatus) {
          this.$message("您上传的表格没有按模板编辑，请重新修改")
        } else {
          this.bringVolActive++;
        }
      } else if (this.bringVolActive == 2) {
        if (this.importProgress != 100) {
          this.$message("请等待文件导入完成")
        } else {
          this.bringVolActive++;
        }
      } else if (this.bringVolActive == 3) {
        this.visible = false;
        this.bringVolActive = 0;
        this.handleClose(1);
      } else {
        if (this.fileStatus) {
          this.bringVolActive++;
          if (this.bringVolActive == 1) {
            this.previewHeader();
          } else if (this.bringVolActive == 2) {
            this.executeImport()
          }
        } else {
          this.$message("请上传文件")
        }
      }
    },

    //执行导入
    executeImport() {
      //导入进度加载(网速慢)
      let timer = setInterval(() => {
        let situation = '';
        situation = Math.floor(Math.random() * (0 - 10) + 10);
        this.importProgress += situation;
        if (this.importProgress > 80) {
          clearInterval(timer)
        }
      }, 500)
      //导入请求
      let param = {}
      let fetchUrl = this.api.assembly.save1 + '?listKey=' + this.listKey + '&recordKey=' + this.recordKey + '&resourceType=' + this.resourceType
      if (this.topicLibraryId){
        fetchUrl = this.api.assembly.save1 + '?listKey=' + this.listKey + '&recordKey=' + this.recordKey + '&resourceType=' + this.resourceType + '&thematicId=' + this.topicLibraryId
      }

      this.$axios(fetchUrl, param, 'post').then(res => {
        if (res.status) {
          clearInterval(timer)
          this.zhaungStatus = 'success'
          this.importProgress = 100;
          this.zhagntaiWenzi = '数据导入成功'
        } else {
          clearInterval(timer)
          this.zhaungStatus = 'exception';
          this.zhagntaiWenzi = '数据导入失败'
        }
      })
    },

    //获取临时预览表头
    previewHeader() {
      this.$axios(this.api.assembly.getTableByResourceName, {
        resourceName: /*this.resourceName*/'资源采集/上传表格',
      }, 'get').then(res => {
        if (res.status) {
          this.listHead = res.data.data;
          this.previewData()
        }
      })
    },

    //获取临时预览数据
    previewData() {
      this.$axios(this.api.assembly.list1, {
        listKey: this.listKey,
        current: this.pageNo,
        size: this.pageSize,
        resourceType: this.resourceType,
      }, 'get').then(res => {
        if (res.status) {
          this.fileList = JSON.parse(res.data);
        }
      })
    },

    //上传成功后返回的数据
    fileDataGetDataList(res) {
      console.log(res)

      if (!res || !res.status) {
        return
      }
      this.$message.success("上传成功")
      this.fileStatus = true;
      //识别该文件缓存的东西
      this.listKey = res.response.data.listKey;
      this.recordKey = res.response.data.recordKey;
      this.total = parseInt(res.response.data.total);
      this.uploadStatus = res.response.data.release;
      const justSkip = res.response.data.justSkip;
      if (justSkip) {
        this.bringVolActive = 1;
        this.previewHeader();
      }

      //异常展示
      this.showAbnormal = true;
      const shuju = JSON.parse(res.response.data.errorList);
      if (shuju) {
        this.successTotal = shuju.successTotal;
        this.errorTotal = shuju.errorTotal;
        this.errorList = shuju.errorList;
      }
    },
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.previewData();
    },
    currentChangeHandle(val) {
      this.pageNo = val;
      this.previewData();
    },

    //重新上传
    reUpload() {
      this.$axios(this.api.assembly.goBack, {
        listKey: this.listKey,
        recordKey: this.recordKey,
      }, 'get').then(res => {
        if (res.status) {
          this.uploadStatus = false;
          this.showAbnormal = false;
          this.fileStatus = false;
          this.bringVolActive = 0;
        }
      })
    },

    //表格模板下载
    downloadTemplate() {
      this.$axios(this.api.assembly.downloadExcel1, {
        //这两个参数都要做判断改
        resourceType: this.resourceType,
        originalId: '957966810039189504',
      }, 'get', 'blob').then(res => {
        let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'})
        // let filename = res.headers['content-disposition']
        let filename = this.resourceName;
        let link = document.createElement('a')
        link.download = decodeURI(filename)
        link.href = window.URL.createObjectURL(blob)
        link.click()
      })
    },

    //下载导入结果
    downresult() {
      this.$axios(this.api.assembly.downloadResultExcel, {
        //这两个参数都要做判断改
        resourceType: this.resourceType,
        listKey: this.listKey,
      }, 'get', 'blob').then(res => {
        let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'})
        // let filename = res.headers['content-disposition']
        let filename = this.resourceName;
        let link = document.createElement('a')
        link.download = decodeURI(filename)
        link.href = window.URL.createObjectURL(blob)
        link.click()
      })
    },

    //弹窗关闭
    handleClose(num) {
      this.fileStatus = false;
      this.visible = false;
      this.showAbnormal = false;
      this.bringVolActive = 0;
      //上传成功后，刷新列表
      if (num == 1) {
        this.$emit('refreshDataList');
      }
    },
  }
}
</script>

<style scoped>
.table >>> .el-table__body-wrapper {
  height: calc(100% - 50px) !important
}
</style>
